import React, { useEffect } from 'react'
import './showsCategorySidebar.css'
import PodcastService from "../../services/PodcastService/podcast.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sideBarImage from '../../assets/images/organon-images/podcast_long.jpg'


const ShowsCategory = () => {
  const { fetchShowsData, showsData } = PodcastService();
  const history = useHistory();
  const redirectToEpisodeHandle = (id) => {
    history.push(`/shows/${id}`);
  }

  useEffect(() => {
    fetchShowsData()
  }, [])


  return (
    <>
      <div className='shows-category__container'>
        <h1 className="show-category__titile">Welcome to Podcasts&nbsp;@&nbsp;Organon!</h1>
        <p className="show-category__description">Our internal podcasting platform brings you in-depth discussions about topics you care about and that are vital to our company. It's flexible, so you can listen when and where it's convenient for you - for example, on your lunch break, during your commute, or on a walk.</p>
      </div></>
  )
}

export default ShowsCategory
