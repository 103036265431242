import React, { useEffect } from 'react'
import './showDetailsSidebar.css';
import PodcastService from "../../services/PodcastService/podcast.service";


const ShowDetailsSidebar = () => {
  const { fetchShowData, showData } = PodcastService();

  useEffect(() => {
    fetchShowData()
  }, [])

  return (
    <>
      <div className='show-details__container'>
        <div className='show-details__image'>
          <img src={showData.image_url} className='show-details-img' />
        </div>
        <div className='show-details__details'>
          <div className='show-details__title'>{showData.title}</div>
          <div className='show-details__description'>{showData.description}</div>
        </div>
      </div>
    </>

  )
}

export default ShowDetailsSidebar
