import { useState } from "react";
import axios, { Axios } from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PodcastService = () => {
    const baseUrl = 'https://www.workperfectly.com';
    // const baseUrl = 'http://0.0.0.0:8080';
    const [response, setResponse] = useState();
    const [showsData, setShowsData] = useState([]);
    const [showData, setShowData] = useState([]);
    const { id } = useParams();

    const addAnalyticsEvent = async (podcastGuid, eventGuid, event)=>{
        const url = `${baseUrl}/api/analytics/events`;
        let sessionGuid;
        try{
            sessionGuid = sessionStorage.getItem('session');
            if (!sessionGuid){throw new Error('Session GUID not found')}
        }catch(er){
            sessionGuid = crypto.randomUUID();
            sessionStorage.setItem('session', sessionGuid)
        }
        const payload = {
            session: sessionGuid,
            podcast_guid: podcastGuid,
            guid: eventGuid,
            event: event
        }

        await axios.post(url, payload);
    }

    const fetchPodcasts = () => {
        return axios.get(`${baseUrl}/api/show/${id}/rss`).then(res => {
            if (res) setResponse(res['data'])
        }).catch(err => { }
        )
    }

    const fetchShowData = () => {
        axios.get(`${baseUrl}/api/show/${id}`).then(res => {
            if (res) {
                setShowData(res['data'])
            }
        }).catch(err => { }
        )

    }

    const fetchShowsData = (search = "") => {
        axios.get(`${baseUrl}/api/integrations/company/rss/shows`, {
            params: { search },
            headers: { 'Messy-Integration-Company': 'organon' }
        }).then(res => {
            if (res) {
                setShowsData(res['data']?.shows)
            }
        }).catch(err => { }
        )
    }


    return {
        fetchPodcasts,
        response,
        fetchShowsData,
        showsData,
        fetchShowData,
        showData,
        addAnalyticsEvent
    }
}


export default PodcastService;