import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import './showsCard.css';

const ShowsCard = ({ heading, creator, episodeCount, id, img, routeName }) => {
  const history = useHistory();
  const redirectToEpisodeHandle = () => {
    history.push(`/shows/${id}`);
  }

  return (
    <div className='shows-card__outer-container'
      onClick={redirectToEpisodeHandle}>
      <div className='shows-img-container'>
        <img className="shows-img" src={img} />
      </div>
      <div className='shows-heading text-align--center'>{heading}</div>
      <div className='shows-creator'>by {creator}</div>
      <div className='episode-count text-align--center'>{episodeCount} <>{episodeCount > 1 ? "episodes" : 'episode'}</></div>
    </div>
  )
}

export default ShowsCard
