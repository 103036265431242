import primaryLogo from "../../../assets/images/organon-images/Organon_Logo_CMYK-with tag.jpg";
import React from "react";
import './topNavBar.css';
import { useHistory } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useMsal } from "@azure/msal-react";

const TopNavBar = () => {

    const history = useHistory();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
        setTimeout(() => history.push(appRoutes.LOGIN));
    }

    const handleLogoClick = () => {
        history.push(appRoutes.SHOWS );
    }

    return (
        <div className="app__header">
            <img onClick={handleLogoClick} src={primaryLogo} alt='Organon logo' className='app__header--logo' />
            {<span onClick={handleLogout}
                    className='app_header__action'
                >
                    Log out
                </span>
            }
        </div>
    )
}

export default TopNavBar;