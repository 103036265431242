import React from "react";
import "./BottomFooter.css";

const BottomFooter = () => {
    return (
        <div className="bottom--footer_container">
            <p className="bottom--footer_text footer--text_small">Use <a className="bottom--footer_link" href="https://www.workperfectly.com" target="_blank">workPERFECTLY</a> only with information intended for internal distribution within Organon. Confidential and Sensitive information may not be shared.</p>
        </div>
    );
};

export default BottomFooter;