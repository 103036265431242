import React, {useEffect, useRef, useState} from "react";
import '../../views/ListPodcasts/listPodcasts.css';
import PodcastService from "../../services/PodcastService/podcast.service";
import moment from "moment";
import 'react-h5-audio-player/lib/styles.css';
import {parseString} from 'xml2js';
import {UndoOutlined, RedoOutlined} from "@ant-design/icons";
import './../EpisodesList/episodesList.css';
import { Spinner } from "../../shared/components/Spinner/Spinner";

const EpisodeCard = () => {
  
  let audioRef=useRef([]);

  const { fetchPodcasts, response, addAnalyticsEvent } = PodcastService();
  const [parsedXml, setParsedXml] = useState();
  const [podcastItems, setPodcastItems] = useState([]);

  useEffect(()=>{
      fetchPodcasts().then()
  },[])

  const triggerAnalytics = async (e)=>{
    const podcastGuid = e.target.src.split('/').at(-1).split('.').at(0);
    let eventGuid;
        try{
            eventGuid = sessionStorage.getItem(podcastGuid);
            if (!eventGuid){throw new Error('Event GUID not found')}
        }catch(er){
            eventGuid = crypto.randomUUID();
            sessionStorage.setItem(podcastGuid, eventGuid)
        }
    const event = {
        type: e.type,
        time: e.target.currentTime,
        speed: e.target.defaultPlaybackRate
    }
    await addAnalyticsEvent(podcastGuid, eventGuid, event)
  }

  useEffect(()=>{
      setPodcastItems([])
      response && parseString(response,(err, result) => {
            setParsedXml(result['rss']['channel'][0]);
      }
      );
  },[response])
  useEffect(()=>{},[podcastItems]);
  useEffect(()=>{
        parsedXml?.item?.length &&
        setPodcastItems(
            parsedXml?.item?.sort((a, b) => moment(b.pubDate[0]).diff(moment(a.pubDate[0])))
        );
    },[parsedXml])
  return (
    (podcastItems)?
    podcastItems.map((item,i) =>(
    <div className="episode-box">
      <div className="list-podcast__item">
          <div className={"list-podcast__content"}>
              <div className={"list-podcast__item__title"}>{item.title[0]}</div>
              <div className={"list-podcast__item__description"}>{item.description[0]}</div>
              <div className={"list-podcast__item__created"}>
                    {moment(item?.pubDate[0]).format(' DD MMMM YYYY')} /
                    {moment.utc(item.duration[0]*1000).format(' HH:mm:ss')}
              </div>
              <div className={"list-podcast__item__content"}>
                  <div className={'list-podcast__player__controls'}>
                      <UndoOutlined onClick={()=>{
                            const audio = audioRef?.current[i]
                            audio.currentTime = audio?.currentTime - 15
                      }}/>
                      <RedoOutlined onClick={()=>{
                            const audio = audioRef?.current[i]
                            audio.currentTime = audio?.currentTime + 15
                      }}/>
                  </div>
                  <audio controlsList={'nodownload'}
                        controls
                        className={'list-podcast__player'}
                        ref={el => audioRef.current[i]=el}
                        key={"audio_"+i}
                        src={item?.link[0]}
                        onPlay={(e)=>{triggerAnalytics(e)}}
                        onPause={(e)=>{triggerAnalytics(e)}}
                        onSeeked={(e)=>{triggerAnalytics(e)}}
                        onEnded={(e)=>{triggerAnalytics(e)}}
                  >
                  </audio>
              </div>
          </div>
        </div>
    </div>)):
    <Spinner />
  )
}

export default EpisodeCard
