import React, { useEffect, useState } from 'react'
import ShowsCard from '../ShowsCard/index';
import './showsList.css'
import PodcastService from "../../services/PodcastService/podcast.service";
import { Button, Input, Space } from 'antd';

const ShowsList = () => {
    const { fetchShowsData, showsData } = PodcastService();
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        fetchShowsData(searchString)
    }, [])

    const onSearchStringChange = (e) => {
        setSearchString(e.target.value)
    }

    const searchHandle = () => {
        fetchShowsData(searchString)
    }

    return (
        < >
            <Space.Compact style={{ width: '100%' }} className="search--container">
                <Input className="search--field" onChange={onSearchStringChange} onPressEnter={searchHandle} defaultValue={searchString} placeholder="Search a specific show" />
                <Button className="search--button" type="primary" onClick={searchHandle}>SEARCH</Button>
            </Space.Compact>
            <div className='shows-list__container'>
                {
                    (showsData && showsData.length > 0) ? showsData?.map((show) => {
                        return <ShowsCard
                            id={show.id}
                            heading={show.title}
                            episodeCount={show.episode_count > 1 ? show.episode_count : 0}
                            img={show.image_url}
                            creator={show.creator_name}
                            routeName={show.custom_url || show.id}
                            key={show.id}
                        />
                    }) : <div className="shows-list__container--empty text--center text--primary text--big">No shows available!</div>
                }

            </div>
        </>
    )
}

export default ShowsList
