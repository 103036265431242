import React, { useEffect } from "react";
import "./Login.css";
import loginPlaceholder from "../../assets/images/organon-images/podcast.jpg";
import { Button } from "antd";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Login = () => {

	const history = useHistory();
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const handleLogin = () => {
		history.push('/shows')
		instance.loginRedirect(loginRequest).then(r => {

		}).catch();
	}

	useEffect(() => {
		isAuthenticated && history.push(appRoutes.SHOWS)
	}, [isAuthenticated])

	return (
		<div className="app-wrapper login">
			<div className='login__container'>
				<div className='login__column1'>
					<div className='login__content'>
						<h1>Welcome to<br />Podcasts&nbsp;@&nbsp;Organon</h1>
						<p>
							Our internal podcasting platform brings you in-depth discussions about topics you care about and that are vital to our company. It's flexible, so you can listen when and where it's convenient for you - for example, on your lunch break, during your commute, or on a walk.
						</p>
					</div>

					<Button type={'primary'}
						onClick={handleLogin}
						className='login__action'
					>
						SIGN IN
					</Button>
				</div>
				<div className='login__column2'>
					<img src={loginPlaceholder} alt='' className='login__placeholder' />
				</div>
			</div>
		</div>
	);
};

export default Login;
